import {
  backgroundImage,
  backgroundImageText,
  container,
  grayColor,
  hexToRgb,
  mlAuto,
  mrAuto,
  section,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const participationStyle = (theme) => ({
  backgroundImage,
  cardBody: {
    margin: "10px auto",
    padding: "0",
    maxWidth: "300px",
    listStyle: "none",
    fontWeight: "500",
    "& li": {
      marginBottom: "5px",
    },
    "& li:last-child": {
      marginBottom: "0px",
    },
  },
  cardContent: {
    ...backgroundImageText,
  },
  cardLayeredLeft: {
    ...mlAuto,
    marginRight: "-75px",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      marginRight: "-50px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
  },
  cardLayeredRight: {
    ...mrAuto,
    marginTop: "100px",
    zIndex: "0",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
  },
  cardPrice: {
    height: "100%",
    fontSize: "2.25rem",
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  container: {
    ...container,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "650px",
    },
  },
  iconWrapper: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "2px solid " + grayColor[14],
    borderRadius: "50%",
    lineHeight: "174px",
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important",
  },
  listTitle: {
    margin: "30px 0 10px 0",
    fontWeight: "500",
    textDecoration: "underline",
  },
  participationCardLeft: {
    textAlign: "center",
    backgroundPosition: "right",
    display: "flex",
    flexFlow: "row nowrap",
  },
  participationCardRight: {
    textAlign: "center",
    backgroundPosition: "center",
    display: "flex",
    flexFlow: "row nowrap",
  },
  participationCardSubtitle: {
    fontWeight: "500",
    textTransform: "uppercase",
  },
  participationCardTitle: {
    fontWeight: "500",
    marginBottom: "20px",
    marginTop: "5px",
  },
  participationTitleItem: {
    ...mlAuto,
    ...mrAuto,
    ...textCenter,
  },
  section: {
    ...section,
    padding: "40px 0",
    position: "relative",
    backgroundSize: "initial",
    "& $container": {
      position: "relative",
      zIndex: "2",
    },
    "& $title": {
      marginBottom: "10px",
    },
  },
  title,
})

export default participationStyle
