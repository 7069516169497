// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
import funkyLinesPattern from "assets/img/backgrounds/funky-lines.png"
// page styles
import participationStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/participationStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
// react libraries
import Flip from "react-reveal/Flip"

const useStyles = makeStyles(participationStyle)

export default function SectionParticipation() {
  const { chiliCook, homeDepot } = useStaticQuery(graphql`
    query getParticipationImages {
      chiliCook: file(relativePath: { eq: "backgrounds/chili-cook.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      homeDepot: file(relativePath: { eq: "backgrounds/home-depot.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div
      className={classes.section}
      style={{ backgroundImage: `url(${funkyLinesPattern})` }}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.participationTitleItem}
          >
            <h2 className={classes.title}>Join in the Chilimania Fun!</h2>
          </GridItem>
        </GridContainer>
        <GridContainer className={classes.marginTop20}>
          <GridItem xs={12} sm={6} md={5} className={classes.cardLayeredLeft}>
            <Flip left delay={1000}>
              <Card className={classes.participationCardLeft} background>
                <GatsbyImage
                  image={chiliCook.childImageSharp.gatsbyImageData}
                  className={classes.backgroundImage}
                  objectPosition={"-165px 0px"}
                  alt="Blog Post 1"
                />
                <div className={classes.cardContent}>
                  <CardBody background>
                    <h3 className={classes.participationCardTitle}>
                      CASI/Traditional Chili
                    </h3>
                    <h4 className={classes.participationCardSubtitle}>
                      Chili &amp; Salsa Competitions
                    </h4>
                    <div className={classes.iconWrapper}>
                      <h3 className={classes.cardPrice}>$20</h3>
                    </div>
                    <h5 className={classes.listTitle}>Price Includes</h5>
                    <ul className={classes.cardBody}>
                      <li>
                        <b>-</b> One entry into the top Chili and Salsa contest
                      </li>
                      <li>
                        <b>-</b> A 10 ft. by 10 ft. booth space
                      </li>
                      <li>
                        <b>-</b> Each cook must make minimum 2 gallons of chili
                        for public tasting
                      </li>
                    </ul>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter} pricing>
                    <Link to="/register">
                      <Button className={classes.aboutButton} color="primary">
                        Register
                      </Button>
                    </Link>
                  </CardFooter>
                </div>
              </Card>
            </Flip>
          </GridItem>
          <GridItem xs={12} sm={6} md={5} className={classes.cardLayeredRight}>
            <Flip left>
              <Card className={classes.participationCardRight} background>
                <GatsbyImage
                  image={homeDepot.childImageSharp.gatsbyImageData}
                  className={classes.backgroundImage}
                  alt="Blog Post 1"
                />
                <div className={classes.cardContent}>
                  <CardBody background>
                    <h3 className={classes.participationCardTitle}>
                      Partner With Chilimania
                    </h3>
                    <h4 className={classes.participationCardSubtitle}>
                      Corporate Sponsorships
                    </h4>
                    <div className={classes.iconWrapper}>
                      <h3 className={classes.cardPrice}>$200+</h3>
                    </div>
                    <h5 className={classes.listTitle}>Reasons to Sponsor</h5>
                    <ul className={classes.cardBody}>
                      <li>
                        <b>-</b> Help us raise money for college scholarships
                        {" &"} community donations.
                      </li>
                      <li>
                        <b>-</b> Brand association with a festival with a
                        29-year track record of success.
                      </li>
                      <li>
                        <b>-</b> Free tickets and other perks at the year’s
                        biggest party!
                      </li>
                    </ul>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter} pricing>
                    <Link to="/sponsor-application">
                      <Button color="primary">Sponsor Info</Button>
                    </Link>
                  </CardFooter>
                </div>
              </Card>
            </Flip>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
